<template>
    <Page
        title="Completing Google"
        icon="cloud_download"
        :loading-message="loadingMessage"
        :show-loading="isShowLoading"
        :error-message="errorMessage"
        :show-error="isShowError"
    >
        hello
        {{ errorMessage }}

        <v-layout slot="actions"> <btn color="success" @click="goBack" small>Back</btn> </v-layout>
    </Page>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    data() {
        return {
            errorMessage: '',
            isShowError: false,
            isShowLoading: false,
            loadingMessage: '',
        };
    },
    methods: {
        ...mapActions(['completeGoogle']),
        goBack() {
            this.$router.push('connectgoogle');
        },
    },
    async created() {
        // this.loadingMessage = 'Configuring google';
        // this.isShowLoading = true;


        const { code, error } = this.$route.query;

        console.log('complete google ',{code,error});

        // if (code) {
        //     await this.completeGoogle({
        //         path: this.$route.fullPath,
        //     });
        //     this.isShowLoading = false;
        //     this.$router.push('dataloader');
        // }
        // if (error) {
        //     this.errorMessage = `google access not granted. (${error})`;
        // }
    },
};
</script>
